@import '/css/phone-input-style.css';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  min-height: calc(100vh - 70px);
  /* padding: 1rem 0.5rem; */
  max-width: 1366px;
  margin: 0px auto;
}
.draggable {
  position: fixed;
  top: 100px;
  right: 100px;
  width: 420px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 5px 0;
  z-index: 35;
}

.draggable.medium {
  width: 600px;

  .draggable-content {
    padding: 15px;
    height: 400px;
  }
}

.draggable.wide {
  width: 800px;
}

.draggable-panel {
  /* background-color: #fff; */
  cursor: move;
}

.draggable-content {
  padding: 15px;
  min-height: 400px;
}

.draggable.wide .draggable-content {
  height: 400px;
}

.draggable-close {
  position: absolute;
  top: 16px;
  right: 10px;
  z-index: 99;

  button {
    background: none;
    border: none;
  }
}

.passcodeText {
  font-size: 13px;
  padding: 3px 5px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.react-calendar {
  border: none !important;
  background: transparent !important;
}

.react-calendar__month-view__weekdays {
  text-align: center !important;
  font-weight: normal !important;
  font-size: 12px !important;
  text-decoration: none !important;
}

abbr {
  text-decoration: none !important;
}

.react-calendar__navigation__prev-button {
  font-size: 30px !important;
}

.react-calendar__navigation__next-button {
  font-size: 30px !important;
}

.react-calendar__navigation__label {
  font-size: 15px !important;
  font-weight: 600 !important;
}

.react-calendar__tile--now {
  background: #6a8ab4 !important;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.react-calendar__tile--active {
  background: #037f29 !important;
  color: white !important;
  padding: 5px;
  border-radius: 5px;
}

.react-calendar__month-view__days__day--weekend {
  color: #3d5a80;
}

.react-calendar__navigation button {
  min-width: 20px !important;
}

.w-100 {
  width: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.textBoxWithCount {
  position: relative;
}

/* .textBoxWithCount .chakra-form__helper-text{
    position: absolute;
    right: 10px;
    bottom: 10px;

} */
/* React Base Table Override*/

.BaseTable {
  box-shadow: none !important;
  border: none !important;
  font-size: inherit !important;
  background-color: white !important;
}

.BaseTable__table-main {
  outline: none !important;
}

.BaseTable__header {
  background-color: white !important;
}

.BaseTable__header-row {
  background: white !important;
  font-weight: 400;
}

.BaseTable__row {
  cursor: pointer;
  background-color: white !important;
  border-radius: 0.5rem;
}

.BaseTable__row.active {
  background-color: #e4e6eb;
}

.BaseTable__header-cell {
  background-color: white !important;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
  position: relative;
}

.react-datepicker-wrapper:after {
  content: '';
  background: url('/images/calendarIcon.svg') 0 0 no-repeat;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  right: 10px;
  top: 14px;
}

.fullpage-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.thankyoupage {
  width: 100%;
  height: calc(100vh - 60px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.thankyoupage--header {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  margin: 100px 0 20px;
}

.thankyoupage p {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 60px;
}

.chakra-radio__control {
  background: transparent !important;
  border-color: #3d5a80 !important;
  border-width: 1px !important;
}

.chakra-radio__control::before {
  width: 74% !important;
  height: 74% !important;
  background: #3d5a80 !important;
}

select:focus-visible,
input:focus-visible {
  border-color: #3d5a80 !important;
  box-shadow: none !important;
}

.jobDetailsDescription ul,
.jobDetailsDescription ol {
  padding-left: 15px;
  margin: 15px 0;
}

.fc-h-event .fc-event-main-frame {
  display: block !important;
  line-height: 1.2 !important;
  padding: 2px !important;
}

/* .fc-h-event .fc-event-time {
  width: 100px !important;
  overflow: hidden;
  flex: 1 auto !important;
} */
.static-page-container {
  min-height: calc(100vh - 330px);
}

.static-page-body {
  min-height: 100%;
  margin-top: 40px;
}

.static-page-body p {
  margin-bottom: 10px;
}

.static-page-body ul,
.static-page-body ol {
  padding-left: 5px;
  margin-left: 20px;
}

.static-page-body ul li,
ol li {
  margin-bottom: 8px;
  color: #5b5b5b;
}

/* custom scroll bar css */
.rcs-custom-scroll {
  min-height: 0;
  min-width: 0;
}

.rcs-custom-scroll .rcs-outer-container {
  overflow: hidden;
}

.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
  position: relative;
}

.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
  opacity: 1;
  transition-duration: 0.2s;
}

.rcs-custom-scroll .rcs-inner-container {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  /* margin-right: -10px !important; */
}

.rcs-custom-scroll .rcs-inner-container > div {
  height: auto !important;
}

.rcs-custom-scroll .rcs-inner-container:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.05) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
  transition: height 0.1s ease-in;
  will-change: height;
}

.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after {
  height: 5px;
  transition: height 0.15s ease-out;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
  user-select: none;
}

.rcs-custom-scroll .rcs-custom-scrollbar {
  position: absolute;
  height: 100%;
  width: 0px;
  right: 0px;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.4s ease-out;
  padding: 6px 0;
  box-sizing: border-box;
  will-change: opacity;
  pointer-events: none;
}

.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
  right: auto;
  left: 3px;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
  opacity: 1;
}

.rcs-custom-scroll .rcs-custom-scroll-handle {
  position: absolute;
  width: 100%;
  top: 0;
}

.rcs-custom-scroll .rcs-inner-handle {
  height: calc(100% - 12px);
  margin-top: 6px;
  background-color: rgba(12, 41, 58, 0.2);
  border-radius: 3px;
}

.event-description p {
  margin-bottom: 15px;
}

.event-description ul,
.event-description ol {
  margin: 16px 0;
  padding-left: 40px;
}

.rdrCalendarWrapper {
  color: #000000;
  font-size: 12px;
}

.rdrDateDisplayWrapper {
  background-color: rgb(239, 242, 247);
}

.rdrDateDisplay {
  margin: 0.833em;
}

.rdrDateDisplayItem {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}

.rdrDateDisplayItem input {
  cursor: pointer;
  height: 2.5em;
  line-height: 2.5em;
  border: 0px;
  background: transparent;
  width: 100%;
  color: #849095;
}

.rdrDateDisplayItemActive {
  border-color: #3d5a80;
}

.rdrDateDisplayItemActive input {
  color: #7d888d;
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers {
  font-weight: 600;
}

.rdrMonthAndYearPickers select {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  background: transparent;
  padding: 10px 30px 10px 10px;
  border-radius: 4px;
  outline: 0;
  color: #3e484f;
  background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>")
    no-repeat;
  background-position: right 8px center;
  cursor: pointer;
  text-align: center;
}

.rdrMonthAndYearPickers select:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.rdrMonthPicker,
.rdrYearPicker {
  margin: 0 5px;
}

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #eff2f7;
}

.rdrNextPrevButton:hover {
  background: #e1e7f0;
}

.rdrNextPrevButton i {
  display: block;
  width: 0;
  height: 0;
  padding: 0;
  text-align: center;
  border-style: solid;
  margin: auto;
  transform: translate(-3px, 0px);
}

.rdrPprevButton i {
  border-width: 4px 6px 4px 4px;
  border-color: transparent rgb(52, 73, 94) transparent transparent;
  transform: translate(-3px, 0px);
}

.rdrNextButton i {
  margin: 0 0 0 7px;
  border-width: 4px 4px 4px 6px;
  border-color: transparent transparent transparent rgb(52, 73, 94);
  transform: translate(3px, 0px);
}

.rdrWeekDays {
  padding: 0 0.833em;
}

.rdrMonth {
  padding: 0 0.833em 1.666em 0.833em;
}

.rdrMonth .rdrWeekDays {
  padding: 0;
}

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
  display: none;
}

.rdrWeekDay {
  font-weight: 400;
  line-height: 2.667em;
  color: rgb(132, 144, 149);
}

.rdrDay {
  background: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  line-height: 3em;
  height: 3em;
  text-align: center;
  color: #1d2429;
}

.rdrDay:focus {
  outline: 0;
}

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 500;
}

.rdrDayToday .rdrDayNumber span:after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background: #3d91ff;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: #fff;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: #3d5a80;
  font-weight: 600;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #fff !important;
  font-weight: 600;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background: #3d5a80;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
}

.rdrSelected {
  left: 2px;
  right: 2px;
}

.rdrInRange {
  background: #d8dee5;
}

.rdrStartEdge {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  left: 2px;
  background: #3d5a80;
}

.rdrEndEdge {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  right: 2px;
  background: #3d5a80;
}

.rdrSelected {
  border-radius: 5px;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  left: 2px;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  right: 2px;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left-width: 1px;
  left: 0px;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right-width: 1px;
  right: 0px;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 3px;
  left: 0px;
  right: 0px;
  bottom: 3px;
  pointer-events: none;
  border: 0px solid #3d5a80;
  z-index: 1;
}

.rdrDayStartPreview {
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  left: 0px;
}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  right: 2px;
  right: 0px;
}

.rdrDefinedRangesWrapper {
  font-size: 12px;
  width: 226px;
  border-right: solid 1px #eff2f7;
  background: #fff;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: #3d5a80 !important;
  font-weight: 600;
}

.rdrStaticRange {
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  border-bottom: 1px solid #eff2f7;
  padding: 0;
  background: #fff;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #eff2f7;
}

.rdrStaticRangeLabel {
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
}

.rdrInputRanges {
  padding: 10px 0;
}

.rdrInputRange {
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px rgb(222, 231, 235);
  margin-right: 10px;
  color: rgb(108, 118, 122);
}

.rdrInputRangeInput:focus,
.rdrInputRangeInput:hover {
  border-color: rgb(180, 191, 196);
  outline: 0;
  color: #333;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper)
  .rdrDayHovered
  .rdrDayNumber:after {
  content: '';
  border: 1px solid #3d5a80;
  border-radius: 5px;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent;
}

.rdrDayPassive {
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span {
  color: #d5dce0;
}

.rdrDayPassive .rdrInRange,
.rdrDayPassive .rdrStartEdge,
.rdrDayPassive .rdrEndEdge,
.rdrDayPassive .rdrSelected,
.rdrDayPassive .rdrDayStartPreview,
.rdrDayPassive .rdrDayInPreview,
.rdrDayPassive .rdrDayEndPreview {
  display: none;
}

.rdrDayDisabled {
  background-color: rgb(248, 248, 248);
}

.rdrDayDisabled .rdrDayNumber span {
  color: #aeb9bf;
}

.rdrDayDisabled .rdrInRange,
.rdrDayDisabled .rdrStartEdge,
.rdrDayDisabled .rdrEndEdge,
.rdrDayDisabled .rdrSelected,
.rdrDayDisabled .rdrDayStartPreview,
.rdrDayDisabled .rdrDayInPreview,
.rdrDayDisabled .rdrDayEndPreview {
  filter: grayscale(100%) opacity(60%);
}

.rdrMonthName {
  text-align: left;
  font-weight: 600;
  color: #849095;
  padding: 0.833em;
}

.sun-editor .se-toolbar {
  border: none;
  outline: none;
  background: #fff;
}

.sun-editor,
.sun-editor-editable {
  border: none;
  outline: none;
  background: transparent;
}

.sun-editor .se-btn-tray {
  padding: 0px;
}

.sun-editor button {
  color: #5B5B5B !important; /* Use !important if needed to override inline or dynamic styles */
}

.sun-editor .se-btn-module-border {
  border: none;
}

.sun-editor .se-wrapper .se-placeholder {
  padding: 0px;
  color: #959595;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.sun-editor-editable {
  padding: 0px;
  font-family: Inter;
  font-size: 16px;
}

.sun-editor .se-resizing-bar {
  background: transparent;
  padding: 5px 0;
  border: none;
}

.sun-editor .se-resizing-bar .se-navigation {
  font-size: 16px;
  display: none;
}

.sun-editor-editable .__se__float-left {
  float: left;
  margin-right: 25px;
}

.sun-editor-editable .__se__float-right {
  float: right;
  margin-left: 25px;
}

.sun-editor-editable[contenteditable='true'] figure figcaption,
.article-description figure figcaption {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  margin-top: var(--spacing-two-x);
  text-align: center;
  width: 100%;
  background: transparent;
}

.react-datepicker-popper {
  z-index: 10 !important;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-header {
  height: 55px;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-modal-title {
  color: #3d5a80;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.sun-editor .se-dialog-tabs {
  height: auto;
  padding: 10px;
  display: flex;
  border: none;
  gap: 10px;
}

.sun-editor .se-dialog-tabs button.active {
  background: #3d5a80;

  color: #fff;
}

.sun-editor .se-dialog-tabs button {
  background-color: #d9d9d9;
  outline: none;
  padding: 7px 13px;
  transition: 0.3s;
  border-radius: 5px;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-content label {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #5b5b5b;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-form {
  width: 100%;
  height: auto;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 6px;
}

.sun-editor input,
.sun-editor select,
.sun-editor textarea {
  border-color: #e4e6eb;
}

.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-dialog-form-files
  .se-dialog-files-edge-button {
  height: 40px;
  border-color: #e4e6eb;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-content .se-btn-primary {
  background: #3d5a80;
  color: #fff;
  padding: 8px 16px;
  font-size: 16px;
  border-color: #3d5a80;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer {
  height: auto;
  min-height: 55px;
  padding: 10px 15px 0;
  text-align: right;
  display: flex;
  border-top: 1px solid #e4e6eb;
  flex-direction: column;
  align-items: flex-end;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer > div {
  float: none;
  width: 100%;
  display: flex;
}

.googleButton > div {
  width: 100% !important;
  height: 48px !important;
}

.mybridgevariant .chakra-step__indicator[data-status='complete'] {
  background: #3d5a80;
  color: #fff;
}

.mybridgevariant .chakra-step__number[data-status='complete'] {
  color: #fff;
}

.mybridgevariant .chakra-step {
  align-items: flex-start !important;
}

.mybridgevariant .chakra-step__separator {
  top: 20px;
  position: relative;
}

.mybridgevariant .chakra-step__number[data-status='complete'] {
  color: #fff;
}

.mybridgevariant .chakra-step__separator[data-status='complete'] {
  background: #c5c5c5;
  height: 1px;
}

.event-description,
.jobDetailsDescription {
  font-family: 'Inter', sans-serif !important;
  font-size: 16px !important;
}

.event-description * {
  font-family: 'Inter', sans-serif !important;
  font-size: 16px !important;
  background: transparent !important;
  color: #747474 !important;
}

.jobDetailsDescription * {
  font-family: 'Inter', sans-serif !important;
  font-size: 16px !important;
  background: transparent !important;
  color: #5b5b5b !important;
}

.jobDetailsDescription p {
  margin: 1.6rem 0 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
}
.jobDetailsDescription ul,
.jobDetailsDescription ol {
  padding-left: 20px;
  margin-left: 35px;
}

.jobDetailsDescription ul li,
.jobDetailsDescription ol li {
  margin: 1rem 0 !important;
  font-size: 1rem !important;
  line-height: 1.55 !important;
}

.fileInput::file-selector-button {
  border: 1px solid #5b5b5b;
  color: #3d5a80;
  border-radius: 5px;
  font-size: 12px;
  background-color: white;
  height: 30px;
}

.fileInputGrey::file-selector-button {
  border: 0px solid #5b5b5b;
  color: #868585;
  border-radius: 5px;
  font-size: 10px;
  background-color: #dfdfdf;
  height: 22px;
}
.fileInputBlue::file-selector-button {
  border: 1px solid #5b5b5b;
  color: #3d5a80;
  border-radius: 5px;
  font-size: 10px;
  background-color: white;
  height: 22px;
}
.static-page-body h3,
.static-page-body h4,
.static-page-body h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0.75rem 0 1.25rem;
  color: #3d5a80;
}

.static-page-body p {
  color: #5b5b5b;
  word-break: break-word;
}

.fileInput {
  font-size: 14px !important;
  color: #5b5b5b !important;
}

.article-description {
  font-family: 'Inter', -apple-system, system-ui, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell,
    Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif !important;
}

.article-description * {
  font-family: 'Inter', -apple-system, system-ui, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell,
    Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif !important;
  color: rgb(0 0 0/0.9) !important;
}

.article-description h1,
.article-description h2,
.article-description h3,
.article-description h4,
.article-description h5,
.article-description h6 {
  margin-bottom: 0.5em !important;
  font-weight: 700 !important;
}

.article-description p,
.sun-editor-editable p,
.article-description p > span,
.sun-editor-editable p > span {
  /* margin-top: 1.6rem !important; */
  margin-bottom: 1.6rem !important;
  font-size: 1.1rem !important;
  line-height: 1.75 !important;
}

.article-description h1,
.article-description h1 span {
  font-size: 2em !important;
}

.article-description h2,
.article-description h2 span {
  font-size: 1.5em !important;
}

.article-description h3,
.article-description h3 span {
  font-size: 1.2em !important;
}

.article-description h4,
.article-description h4 span {
  font-size: 1em !important;
}

.article-description h5,
.article-description h5 span {
  font-size: 0.875em !important;
}

.article-description h6,
.article-description h6 span {
  font-size: 0.75em !important;
}

.article-description ul,
.article-description ol {
  padding-left: 20px;
  margin-left: 35px;
}

.article-description ul li,
.article-description ol li {
  margin: 1.2rem 0 !important;
  font-size: 1.1rem !important;
  line-height: 1.75 !important;
}
.sun-editor-editable ol,
.sun-editor-editable ul {
  line-height: 1.75;
  margin: 0;
  padding: 0;
}
.sun-editor-editable ol li,
.sun-editor-editable ul li {
  margin: 0.8rem 0 0.8rem 3.2rem;
  padding-left: 0.8rem;
}

.article-description .se-image-container {
  display: flex;
}
.article-description .se-image-container.__se__float-center {
  justify-content: center;
}
.article-description .se-image-container.__se__float-left {
  float: left;
  margin: 0 20px 0px 0;
  display: block;
}
.article-description .se-image-container.__se__float-right {
  float: right;
  margin-left: 20px;
  display: block;
}
.article-description a {
  color: #3d5a80 !important;
}

.sun-editor-editable blockquote,
.article-description blockquote {
  display: block;
  font-family: inherit;
  font-size: inherit;
  color: #3d5a80;
  -webkit-margin-before: 1em;
  margin-block-start: 1.5em;
  -webkit-margin-after: 1em;
  margin-block-end: 1.5em;
  -webkit-margin-start: 0;
  margin-inline-start: 0px;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
  padding: 0 5px 0 20px;
  border: solid #3d5a80;
  border-width: 0 0 0 5px;
  font-style: italic;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.fc-button-group:has(.fc-today-button) {
  min-width: 273px; 
}
@media screen and (max-width: 800px) {
  .fc .fc-toolbar {
    flex-direction: column;
  }
  .fc .fc-popover {
   z-index: 999;
  }
}

.fileInput::file-selector-button {
  border: 1px solid #5b5b5b;
  color: #3d5a80;
  border-radius: 5px;
  font-size: 16px;
  background-color: white;
  height: 40px;
}
.fileInput {
  font-size: 16px !important;
  color: #5b5b5b !important;
}


.fileInput::-webkit-file-upload-button {
  border-radius: 20px; /* Adjust the value as needed */
  color: #3D5A80; /* Optional: Button text color */
  padding: 8px 12px; /* Optional: Button padding */
  border: 1px solid #00000033;
  cursor: pointer; /* Optional: Pointer cursor for better UX */
}

.fileInput input {
  font-size: 16px !important;
  color: #5b5b5b !important;
  border-radius: 50px !important;
}

.article-description figure figcaption {
  padding: 10px 0;
  color: rgba(0, 0, 0, 0.6);
}
.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  min-height: 1000px;
}
.cut-corners-right-bottom {
  position: relative;
}
.cut-corners-right-bottom::after {
  width: 0;
  height: 0;
  border-bottom: 100px solid white;
  border-left: 100px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}

@media screen and (max-width: 460px) {
  #__next {
    height: 100vh;
  }
  main {
    padding: 0;
    min-height: calc(100vh - 110px);
  }
  .fc .fc-button {
    font-size: 0.8em;
  }
  .fc .fc-toolbar-title {
    font-size: 1em;
  }
  .fc .fc-popover {
    z-index: 999;
   }
  .article-description .se-image-container.__se__float-left {
    float: none;
    margin: 0;
    display: block;
  }
  .article-description .se-image-container.__se__float-right {
    float: none;
    margin-left: 0;
    display: block;
  }
  .article-description .se-image-container.__se__float-left img,
  .article-description .se-image-container.__se__float-right img,
  .article-description .se-image-container img,
  .article-description .se-image-container figure {
    width: 100% !important;
    height: auto !important;
  }
  .rdrDefinedRangesWrapper {
    display: none;
  }
  .mobilePlanCards {
    background: #fff;
  }
  .mobilePlanCards:nth-child(2) {
    background: #f6f7f8;
  }
  .mobilePlanCards:nth-child(3) {
    background: #e9eaeb;
  }
  .BaseTable .BaseTable__header {
    display: none;
  }
}

/* Slick Slider styles */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 -10px;
  padding: 0;
  /* height: 300px; */
}
.slick-list:focus {
  outline: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:after,
.slick-track:before {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-initialized .slick-slide > div {
  padding: 0 3px;
}

.center1 .slick-slide > div {
  padding: 0 6px;
}

.slick-next,
.slick-prev {
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 40px;
  line-height: 0;
  padding: 0;
  position: absolute;
  top: 38%;
  /* transform: translateY(-50%); */
  width: 40px;
  background: #fff url('/images/navigateNext.svg') center center no-repeat;
  border: 1px solid #d9d9d9;
  z-index: 2;
  border-radius: 50%;
  margin-top: -20px;
  visibility: hidden;
}

.slick-next.slick-disabled,
.slick-prev.slick-disabled {
  opacity: 0.25;
}

.slick-prev {
  left: 0px;
}
.slick-next {
  right: -8px;
  transform: rotate(180deg);
}
.slick-slider:hover .slick-next,
.slick-slider:hover .slick-prev {
  visibility: visible;
}

.job-textarea {
  min-height: 500px;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #D9D9D9;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #666565;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5B5B5B;
}

 /* .postModalContainer {
  position: relative;
  scrollbar-width: thick;
  scrollbar-color: #bcc0c4 #ebebeb;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding-right: 10px;
} */
/*
.postModalContainer::-webkit-scrollbar,
.postModalContainer::-ms-scrollbar {
  width: 10px;
  opacity: 0;
}
.postModalContainer:hover::-webkit-scrollbar {
  display: block;
  opacity: 1;
}
.postModalContainer::-webkit-scrollbar-track {
  background-color: #ebebeb;
}

.postModalContainer::-webkit-scrollbar-thumb,
.postModalContainer::-ms-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #bcc0c4;
} */

.reelModalContainer {
  position: relative;
  scrollbar-width: thick;
  scrollbar-color: #bcc0c4 #ebebeb;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding-right: 10px;
  overflow-y: hidden !important;
}
.reelModalContainer::-webkit-scrollbar,
.reelModalContainer::-ms-scrollbar {
  width: 10px;
  opacity: 0;
}
.reelModalContainer:hover::-webkit-scrollbar {
  display: block;
  opacity: 1;
}
.reelModalContainer::-webkit-scrollbar-track {
  background-color: #ebebeb;
}

.reelModalContainer::-webkit-scrollbar-thumb,
.reelModalContainer::-ms-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #bcc0c4;
}

.infinite-scroll-component {
  margin: 0 -12.5px;
  padding: 0 10px;
}
@media screen and (max-width: 720px) {
  .infinite-scroll-component {
  margin: 0;
  padding: 0 10px;
}
}
@media screen and (max-width: 500px) {
  .infinite-scroll-component {
  margin: 0;
  padding: 0 0px;
}
}

.bannerBGContainer {
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  display: block;
  max-height: 670px;
  background-repeat: no-repeat;
}
.bannerBGContainer::after {
  background-color: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  backdrop-filter: blur(50px);
  z-index: 0;
}
.bannerBGContainerCompany {
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  /* height: 100%; */
  display: block;
  max-height: 560px;
  background-repeat: no-repeat;
}
.bannerBGContainerCompany::after {
  background-color: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  backdrop-filter: blur(50px);
  z-index: 0;
}

.primaryGradiantButton {
  background: rgb(123, 150, 185);
  background: -moz-linear-gradient(
    180deg,
    rgba(123, 150, 185, 1) 0%,
    rgba(63, 89, 124, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(123, 150, 185, 1) 0%,
    rgba(63, 89, 124, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(123, 150, 185, 1) 0%,
    rgba(63, 89, 124, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7b96b9",endColorstr="#3f597c",GradientType=1);
  box-shadow: 0px 0px 10px 0px #17355d4d, 0px 2px 4px 0px #6f95c6 inset;

  /* box-shadow: 0px 2px 4px 0px #6f95c6 inset; */
}
.tabing-list-only .banner-stack {
  display: none;
}
.tabing-list-only {
  position: sticky !important;
  top: 64px;
  background: white;
  z-index: 1;
}
.tabing-list-only .stack-1 {
  width: 100%;
}
.sticky-team-header {
  width: 100%;
  padding-top: 5px;
  display: none !important;
}
.top-scroll .sticky-team-header {
  display: flex !important;
}

.team-tabs {
  width: 100%;
  box-shadow: none !important ;
  /* margin-top: 40px; */
}

.sticky-team-header .camera-icon {
  display: none;
}

.swiper-button-prev {
  background-color: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.swiper-button-prev::after {
  font-size: 25px;
  color: #5b5b5b;
  font-weight: 800;
}

.swiper-button-next {
  background-color: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.swiper-button-next::after {
  font-size: 25px;
  color: #5b5b5b;
  font-weight: 800;
}

.aboutSection {
  ul {
    margin-left: 15px;
    margin-top: 5px;
  }
  ol {
    margin-left: 18px;
    margin-top: 5px;
  }
}

.businessBanner {
  background-size: cover;
  position: relative;
}
.threadPostAvatar,
.postHasChild,
.postHasChildCreate {
  position: relative;
}
.threadPostAvatar::before {
  position: absolute;
  left: 28px;
  content: '';
  border-left: 2px solid #e4e6eb;
  height: 100%;
  top: 57px;
  display: block;
  height: 97%;
}
.postHasChildCreate::before {
  position: absolute;
  left: 23px;
  content: '';
  border-left: 2px solid #e4e6eb;
  height: calc(100% - 65px);
  top: 56px;
  display: block;
}
.postHasChild::before {
  position: absolute;
  left: 28px;
  content: '';
  border-left: 2px solid #e4e6eb;
  height: calc(100% + 56px);
  top: -16px;
  display: block;
}
.threadPostAvatar:last-child::before {
  display: none;
}
.postHasChildCreate:last-child::before {
  display: none;
}

.postEditorContainer .public-DraftEditorPlaceholder-root {
  top: -6px !important;
  left: 5px;
}
.postEditorContainer .public-DraftStyleDefault-block {
  margin: 0 !important;
  padding: 0 10px;
}

.hstack_large {
  margin:auto
}

.hstack_medium {
  margin: auto !important
}

.hstack_small {
  margin: 0px 0px !important
}

/* .hstack_small > div:nth-child(2) {
  padding-left: 0px !important; 
} */
.hstack_small_search {
  padding-left: 250px !important; /* Example: Add some padding */

}


.hstack_large_loggedOut {
  margin:auto
}

.hstack_medium_loggedOut {
  /* margin: auto !important */
}

.hstack_small_loggedOut {
  margin: 0px 30px !important
}

@media screen and (max-width: 420px) and (min-width: 400px) {
  .hstack_small_loggedOut {
    margin: 0 20px !important;
  }
}

@media screen and (max-width: 399px) and (min-width: 385px) {
  .hstack_small_loggedOut {
    margin: 0 10px !important;
  }
}

@media screen and (max-width: 385px) {
  .hstack_small_loggedOut {
    margin: 0 5px !important;
  }
}

.hstack_small_loggedOut > div:nth-child(2) {
  padding-left: 0px !important;
  flex: unset; 
}
/* .public-DraftEditorPlaceholder-inner{
  margin-top: 5px;
} */
.chakra-popover__popper {
  z-index: 99999 !important;
}
.chakra-modal__body:has(.postEditorContainer)
  .postEditorContainer
  .public-DraftEditorPlaceholder-root {
  top: 6px !important;
  left: 0;
  padding: 0 15px;
}
.react-multiple-carousel__arrow {
  background: #fff;
}
.react-multiple-carousel__arrow::before {
  color: #000;
}
.react-multiple-carousel__arrow:hover{
  background: #fff;
}

/* Document Viewer Plugin Overwrites */
#react-doc-viewer {
  #header-bar {
    display: none;
  }
  #proxy-renderer {
    padding: 20px;
    background-color: #ececec;
    overflow: auto;
    #html-renderer {
      padding: 0;
      #html-body {
        background-color: #ececec;
        border: 0;
        overflow: auto;
      }
    }
  }
}
.swiper {
  width: 100%;
}

textarea {
  resize: none !important;
}
.BaseTable__body {
  background-color: white !important; 
}

.slick-next.slick-disabled, .slick-prev.slick-disabled{
  opacity:0;
}
.react-multiple-carousel__arrow{z-index: 100 !important;}
.fc .fc-button-group > .fc-button{text-transform: capitalize !important;}
.fc .fc-popover {
  z-index: 999;
 }

/* *::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #F5F5F5;
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #CBD5E0;
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #A0AEC0;
} */

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  background: #C8C8C8;
  border-radius: 51px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

*::-webkit-scrollbar-track {
  background: #EDEDED;
}