.statusContainer {
    // background: var(--chakra-colors-gray-200);
    // box-shadow: 0px 2px 6px 0px #00000033!important;
    // border-radius: 50px;
    // height: 44px;
    // width: 210px;
    // padding: 10px 14px;
    // display: flex;
    // gap: 10px;
    // align-items: center;
    // color: var(--chakra-colors-brandPrimary-500);
    // font-size: 15px;
    // border: 1px solid var(--chakra-colors-gray-300);
    cursor: pointer;
    div:last-child{
      // display: none!important;
    }
    @media screen and (max-width:800px) {
      // padding: 10px 0;
    }
  }
  
  
  .moreOption {
    // background: var(--chakra-colors-gray-200);
    // box-shadow: 0px 2px 6px 0px #00000033!important;
    border-radius: 50px;
    height: 50px;
   justify-content: center;
    padding: 10px 13px;
    display: flex;
    // gap: 8px;
    align-items: center;
    width: auto;
    // color: var(--chakra-colors-brandPrimary-500);
    font-size: 18px;
    // border: 1px solid var(--chakra-colors-gray-300);
    cursor: pointer;
  
    &:hover {
      background-color: #b5b4b46e !important;
      }
    @media screen and (max-width:800px) {
      // padding: 10px 0;
    }
    div:last-child{
      display: flex!important;
    }
    .chakra-popover__popper {
      margin-bottom: -50px;
    }
  }
  
  .statusContainerTest{
    // box-shadow: 0px 2px 6px 0px #00000033;
    background-color: #F7F7F7;
    // border-radius: 50px;
    height: 84px;
    // width: 210px;
    // padding: 10px;
    display: flex;
    gap: 6px;
    align-items: center;
    color: var(--chakra-colors-brandPrimary-500);
    font-size: 15px;
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
    // border-bottom: 1px solid var(--chakra-colors-gray-300);
    cursor: pointer;
    div:last-child{
      // display: none!important;
    }
    @media screen and (max-width:800px) {
      // padding: 10px 0;
    }
  }

.statusContainerDivider{
    border-top: 1px solid var(--chakra-colors-gray-300);
}

  .statusContainerTextLast{
    // box-shadow: 0px 2px 6px 0px #00000033;
    border-radius: 50px;
    height: 38px;
    width: 210px;
    padding: 10px;
    display: flex;
    gap: 6px;
    margin-top: 6px;
    margin-bottom: 2px;

    // padding-bottom: 4px;
    // padding-top: 4px;

    align-items: center;
    color: var(--chakra-colors-brandPrimary-500);
    font-size: 15px;
    border-radius: 0px;
   
    cursor: pointer;
    div:last-child{
    //   margin-top: 10px;


    }
    &:hover {
        background-color: var(--chakra-colors-gray-100) !important;
        }

    @media screen and (max-width:800px) {
    //   padding: 10px 0;
    }
  }
  .statusIcon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    margin: 0px !important;
  }
  .statusDropdown {
    max-width: 230px;
    box-shadow: 0px 2px 6px 0px #00000033!important;
    margin-top: -55px;
    // margin-left: 5px;
    // margin-left: -37%;
    position: absolute;
    left: 0%;
    transform: translateX(-35%) !important;// Centers horizontally
    width: fit-content; // Ensures content adapts to its size
    // max-width: 50vw;
    border-top: 1px solid #00000033!important;
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
    // box-shadow: none!important;
    padding: 0px;
    outline: none;
    .dropItem {
      display: flex;
      gap: 10px;
      padding: 10px;
      p {
        color: #272727;
        font-size: 0.9375rem;
        font-weight: 400;
        line-height: 1;
        margin: 0;
      }
      span{
          color: #747474;
          display: block;
          font-size: .75rem;
          font-weight: 400;
          line-height: 1;
          margin-top: 5px;
      }
      &:hover {
      }
      &.small{
        padding: 0.4375rem 10px;
      }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      transform: translateX(-10%) !important;
    }

    @media (min-width: 1024px) and (max-width: 1622px) {
      transform: translateX(-0%) !important;
    }
  }
  .shortcutsIcon {
    width: 24px;
    height: 24px;
    // border-radius: 50%;
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    margin: 0px !important;
  }
  
  .moreDropdown {
    max-width: 250px;
    box-shadow: 0px 2px 6px 0px #00000033!important;
    // margin-top: -55px;
    // margin-left: 5px;
    border-top: 1px solid #00000033!important;
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
    // box-shadow: none!important;
    outline: none;
    .dropItem {
      display: flex;
      gap: 20px;
      padding: 10px;
      height: 45px;
      align-items: center;
      border-radius: 50px;
      width: fit-content;
      padding-right: 30px;
      p {
        color: #575757;
        font-size: 17px;
        font-weight: 600;
        line-height: 1;
        margin: 0;
        margin-left: 10px;
      }
      span{
          color: #747474;
          display: block;
          font-size: .75rem;
          font-weight: 400;
          line-height: 1;
          margin-top: 5px;
      }
      &:hover {
        background-color: #b5b4b46e !important;
        }
      &.small{
        padding: 0.4375rem 10px;
      }
    }
  
    
  }











.left {
  width: 270px;
}


.leftPublic {
  width: 225px;
}
.leftNetwork {
  width: 375px;
  margin-left: 20px;
}
.leftEventDetails {
  width: 215px;
  margin-left: 5px;
}
.leftNetworkMessage {
  width: 375px;
}
.leftTeams {
  width: 275px;
  margin-left: 20px;
}

.leftLoggedOut {
  width: 225px;
}
.moreIconstyle {
  margin-right: 17px;
}


// new responsive files
.rightPannel {
  width:386px;
}

@media (max-width: 1330px) and (min-width: 600px) {
  .paddingLeftSmall {
    overflow-y: auto;
    height: calc(100vh - 80px) !important;
    padding-left: 15px;
    overflow-x: hidden;
  }
  
  /* For Webkit-based browsers (Chrome, Edge, Safari) */
  .paddingLeftSmall::-webkit-scrollbar {
    width: 6px; /* Set the width of the scrollbar */
  }
  
  .paddingLeftSmall::-webkit-scrollbar-thumb {
    background: #888; /* Scrollbar color */
    border-radius: 4px; /* Rounded corners */
  }
  
  .paddingLeftSmall::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color when hovered */
  }
  
  .paddingLeftSmall::-webkit-scrollbar-track {
    background: #f1f1f1; /* Scrollbar track color */
  }
  
  /* For Firefox */
  .paddingLeftSmall {
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
  }

  .pageContainer {
        width:610px;
  }

  .pageContainerPublic {
    width:610px;
}
.tabsContainer {
  overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.tabsContainer::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.rightPannel {
  width:350px;
}
  .hideTitle {
    display: none; 
    width: 48px;
  
}
  .leftpannel{
    display: none;
  }
  .more{
    display:none;
    // width:150px;
    margin-left: 20px;
  }
  .moreOption {
    margin-left: 2px;
    height: 50px;
    width: 50px;
    padding:10px 8px;
  }
  .moreIconstyle{
    margin-right: -3px;
  }
  .left{
    width:91px
  }

  .leftPublic {
    width: 91px;
  }
  
  .leftNetwork {
    width: 91px;
    margin-left: 90px;
  }


  .leftEventDetails {
    width: 91px;
    margin-left: 75px;
  }
   
  .leftNetworkMessage {
    width: 91px;
    margin-left: 90px;
  }
  
  .leftLoggedOut {
    width: 175px;
  }
}
@media  (min-width: 1330px) {
  .hideTitle {
    width: 200px;
  }
  .paddingLeftSmall {
    padding-left: 10px;
    // height: calc(100vh - 50px) !important;
}

      .pageContainer {
        width: 705px;
      }

      .pageContainerPublic {
        width:705px;
    }
}

@media  (max-width: 1330px)and (min-width: 1180px) {
  .leftTeams {
    width: 91px;
    margin-left: 120px;
  }
}
@media  (max-width: 1180px) {
  .leftTeams {
    width: 91px;
    margin-left:60px;
  }
}

@media (max-width: 1000px) and (min-width: 600px) { 
  .left {
    width: 270px; 
  }
  .leftPublic {
    width: 225px;
  }
  .leftNetwork {
    width: 370px;
    margin-left: 20px;
  }
  .leftEventDetails {
    width: 370px;
    margin-left: 20px;
  }
  .leftNetworkMessage {
    width: 370px;
  }
  .leftTeams {
    width: 370px;
    margin-left: 20px;
  }
  .leftLoggedOut {
    width: 225px;
  }

  
}



@media (max-width: 1100px) {
  .rightSidePannel {
    display: none;
  }
  .leftLoggedOut {
    width: 280px;
  }
  .leftNetwork {
    width: 91px;
    margin-left: 0px;
  }
  .leftEventDetails {
    width: 91px;
    margin-left: -10px;
  }
  .leftNetworkMessage {
    width: 91px;
    margin-left: 0px;
  }
  .leftTeams {
    width: 91px;
    margin-left:0px;
  }
}
