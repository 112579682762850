@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

// #no-freeze-spinner {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   transition: all 0.1s linear;
//   opacity: 0;
//   height: 100vh;
//   transform: scale(10);
//   z-index: -1;
//   background: $dark;
// }
#noFreezeSpinner {
  position: relative;
  top: 0;
  left: 0;
  width: 100px;
  transition: all 0.1s linear;
  opacity: 0;
  height: 100px;
  transform: scale(10);
  z-index: -1;
  margin: 0 auto;
}
.noFreezeSpinner #noFreezeSpinner {
  z-index: 9999;
  opacity: 0.5;
  transform: scale(1);
}
#noFreezeSpinner > div > div {
  animation-play-state: paused;
  border: 5px solid var(--chakra-colors-brandPrimary-500);
  border-radius: 50%;
  animation: rotate 1s infinite linear;
  border-left-color: #E4E6EB;
  width: 100%;
  height: 100%;
}
#noFreezeSpinner > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  // box-shadow: 0 0 0 2000px $dark;
  width: 60px;
  height: 60px;
  padding: 5px;
}
.noFreezeSpinner #noFreezeSpinner div div {
  animation-play-state: running;
}
@keyframes loadicons {
  0% {
    transform: scale(0) translate3d(0, 0, 0);
  }
  11% {
    transform: scale(1.2) translate3d(0, 0, 0);
  }
  22% {
    transform: scale(1) translate3d(0, 0, 0);
  }
  33% {
    transform: scale(0) translate3d(0, 0, 0);
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.noFreezeSpinner #noFreezeSpinner i {
  font-size: 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: var(--chakra-colors-brandPrimary-500);
  width: 24px;
  height: 24px;
  line-height: 1;
  transform: scale(0) translate3d(0, 0, 0);
}
.noFreezeSpinner #noFreezeSpinner i:nth-of-type(1) {
  animation: loadicons 3s infinite ease-in-out;
}
.noFreezeSpinner #noFreezeSpinner i:nth-of-type(2) {
  animation: loadicons 3s 1s infinite ease-in-out;
}
.noFreezeSpinner #noFreezeSpinner i:nth-of-type(3) {
  animation: loadicons 3s 2s infinite ease-in-out;
}
