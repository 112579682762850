.notificationCard {
    display: flex;
    padding: 8px;
    cursor: pointer;
    &:hover {
      background-color:var(--chakra-colors-brandGray-200);
    }
    .notificationCardInner {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      flex: auto;
    }
    .notificationCardProfile {
      flex: initial;
      width: 40px;
      height: 40px;
      position: relative;
      .notificationCardIcon {
        position: absolute;
        right: -5px;
        bottom: -5px;
      }
    }
    .notificationCardDetails {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      gap: 2px;
      .notificationActions {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        > li {
          position: relative;
          padding-right: 15px;
          font-weight: 600;
          font-size: 12px;
          &::after {
            content: '|';
            position: absolute;
            right: 5px;
            color: #5b5b5b;
            font-weight: 400;
          }
          &:last-child::after {
            content: '';
          }
        }
      }
      .notificationCardTime {
        font-weight: 400;
        font-size: 12px;
        line-height: normal;
      }
    }
    .notificationCardTitle{
        font-weight: 400;
        font-size: 13px;
        margin: 0px;
        line-height: normal;
        b{
          text-transform: capitalize;
        }
    }
    .notificationCardPreview{
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
    }
    .notificationCardActions {
      display: flex;
      flex: 0;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      flex-direction: column;
      .dropdown-menu {
        background: #ffffff;
        border: 1px solid #dddddd;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        width: 280px;
        .dropdown-item {
          font-weight: 400;
          font-size: 15px;
          white-space: break-spaces;
        }
      }
    }
    .notificationCardStatus {
      background: #3d5a80;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: block;
    }
  }