.recentSearchDashboard {
  display: flex;
  padding: 5px;
  &:hover {
    background-color: #EAEAEA;
    border-radius: 4px;
}
  &.small{
    padding: 0.4375rem 10px;
  }
}
