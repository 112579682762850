.statusContainer {
  // background: var(--chakra-colors-gray-200);
  // box-shadow: 0px 2px 6px 0px #00000033!important;
  border-radius: 50px;
  height: 44px;
  width: 210px;
  padding: 10px 14px;
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--chakra-colors-brandPrimary-500);
  font-size: 15px;
  // border: 1px solid var(--chakra-colors-gray-300);
  cursor: pointer;
  div:last-child{
    display: none!important;
  }
  @media screen and (max-width:800px) {
    // padding: 10px 0;
  }
}

.statusContainerTest{
  // box-shadow: 0px 2px 6px 0px #00000033;
  border-radius: 50px;
  height: 44px;
  width: 210px;
  padding: 10px;
  display: flex;
  gap: 6px;
  align-items: center;
  color: var(--chakra-colors-brandPrimary-500);
  font-size: 15px;
  border-radius: 0px;
  border-bottom: 1px solid var(--chakra-colors-gray-300);
  cursor: pointer;
  div:last-child{
    display: none!important;
  }
  @media screen and (max-width:800px) {
    // padding: 10px 0;
  }
}
.statusIcon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  margin: 0px !important;
}
.statusDropdown {
  // max-width: 230px;
  border: 2px solid #e7e7e7;
  border-radius: 8px;
  // box-shadow: 0px 2px 6px 0px #00000033!important;
  // margin-top: -55px;
  // margin-left: 5px;
  // border-top: 1px solid #00000033!important;
  // border-top-right-radius: 20px !important;
  // border-top-left-radius: 20px !important;
  // box-shadow: none!important;
  outline: none;
  .dropItem {
    display: flex;
    gap: 10px;
    padding: 10px;
    p {
      color: #272727;
      font-size: 0.9375rem;
      font-weight: 400;
      line-height: 1;
      margin: 0;
    }
    span{
        color: #747474;
        display: block;
        font-size: .75rem;
        font-weight: 400;
        line-height: 1;
        margin-top: 5px;
    }
    &:hover {
    }
    &.small{
      padding: 0.4375rem 10px;
    }
  }
}
