.header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
}

.avatarBox{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E4E6EB;
    border-radius:4px;
    width:50px;
}

.boxIcon{
    position: absolute;
    border: 1px solid #ffffff;
    background: #D9D9D9;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    top: 60%;
    color: #5B5B5B;
    left: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        position: relative;
    }
}

.boxIconPost{
    position: absolute;
    border: 1px solid #ffffff;
    background: #D9D9D9;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 65%;
    left: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        width: 6px;
        height: 6px;
        position: relative;
    }
}

.alignCenter{
    text-align: center;
}

.networkBox::-webkit-scrollbar-track {
    // display: none;
    background-color: whitesmoke !important
}

.networkBox::-webkit-scrollbar-thumb{
    background-color: rgb(190, 187, 187) !important
}
.networkBox::-webkit-scrollbar {
    width: 6px; /* Adjust the width as needed */
  }

/* Hide scrollbar for IE, Edge and Firefox */
.networkBox {
    overflow: hidden;
//   -ms-overflow-style: none;  /* IE and Edge */
//   scrollbar-width: none;  /* Firefox */
}

.networkBox:hover {
    overflow-y: auto; /* Show vertical scrollbar */
  }

.searchPopUp {
  // max-width: 230px;
  box-shadow: 0px 2px 6px 0px #00000033!important;
  margin-top: -55px;
  // margin-left: -46px;
//   border-top: 1px solid #00000033!important;
  border-top-right-radius: 25px !important;
  border-top-left-radius: 25px !important;
  // box-shadow: none!important;
  outline: none;
//   margin-left: 20px;
top:0px
}

.searchPopUpBottomLine{
  color: var(--chakra-colors-brandPrimary-500);
  border-bottom: 1px solid var(--chakra-colors-gray-300);
}
.searchGroup {
    padding: 8px;
}