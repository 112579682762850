.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.avatarBox{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:4px;
  width:50px;
}
.avatarBox:hover {
  background-color: #E4E6EB;
}
.boxIcon{
  position: absolute;
  border: 1px solid #ffffff;
  background: #D9D9D9;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 60%;
  color: #5B5B5B;
  left: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
      position: relative;
  }
}

.boxIconPost{
  position: absolute;
  border: 1px solid #ffffff;
  background: #D9D9D9;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 65%;
  left: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
      width: 6px;
      height: 6px;
      position: relative;
  }
}

.alignCenter{
  text-align: center;
}

.networkBox::-webkit-scrollbar-track {
  // display: none;
  background-color: whitesmoke !important
}

.networkBox::-webkit-scrollbar-thumb{
  background-color: rgb(190, 187, 187) !important
}
.networkBox::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed */
}

/* Hide scrollbar for IE, Edge and Firefox */
.networkBox {
  overflow: hidden;
//   -ms-overflow-style: none;  /* IE and Edge */
//   scrollbar-width: none;  /* Firefox */
}

.networkBox:hover {
  overflow-y: auto; /* Show vertical scrollbar */
}

.searchPopUp {
// max-width: 230px;
box-shadow: 0px 2px 6px 0px #00000033!important;
margin-top: -55px;
// margin-left: -46px;
// border-top: 1px solid #00000033!important;
border-top-right-radius: 25px !important;
border-top-left-radius: 25px !important;
// box-shadow: none!important;
outline: none;
}

.searchPopUpBottomLine{
color: var(--chakra-colors-brandPrimary-500);
border-bottom: 1px solid var(--chakra-colors-gray-300);
}
.searchGroup {
  padding: 8px;
}

.paddingResponsive {
  padding-left: 0px; 
}

.forAuto{
  width: 290px;
 }
@media (min-width: 1331px) {
  .paddingResponsive {
    padding-right: 140px;
    width: 18%;
  }
  .forAuto{
    width: 18%;
  }
}

@media (min-width: 1000px) {
  .forAuto{
    width: 285px;
    // padding-left: -10px;
  padding-left:20px
  }
}


@media (min-width: 1000px) {
  .paddingResponsive{
    width: 230px;
    // padding-left: -10px;
    left: -10px;
  }
}

// @media (max-width: 1000px) {
//   .forAuto{
//     width: 290px;
//   }
// }
@media (max-width: 1100px) {
  .forAuto{
    width: 190px;
    padding-left: 0px;
  }
}
@media (max-width: 1300px) { 
  .paddingResponsive {
    padding-left: 20px; 
    width:9%
  }
 

}

@media (max-width: 1050px) { 
  .paddingResponsive {
    padding-left: 0px; 
    width:6%
  }
  .forAuto{
    width: 20%;
  }
}

//

@media (min-width: 1330px) {
  .paddingResponsivelogout {
    padding-right: 200px;
    width: 230px;
    justify-content: center;
  }
}
@media (max-width: 1300px) { 
  .paddingResponsivelogout {
    width:28%
  }
}

@media (max-width: 1301px) { 
  .paddingResponsivelogout {
    padding-left: 0px; 
    width:30%
  }
}



.rightResponsiveMoblie{
  width:320px;
  padding-left:15px !important
}

.rightResponsiveMoblieLogedIn{
  width:120px;
  padding-left:15px !important
}

.rightResponsive {
  width:719px ;
  padding-left:52px !important
}

@media (max-width: 1300px) { 
  .rightResponsive {
      width:622px
  }
}
@media (max-width: 1280px) { /* Adjust for 110% zoom on 1300px */
  .rightResponsive {
      width: 620px; /* Width for 110% zoom */
  }
}
@media (max-width: 1050px) { 
  .rightResponsive {
      width:590px
  }
}
@media (max-width: 1000px) { /* Adjust this value as needed */
  .rightResponsive {
    width: 400px;
    margin-left: 20px;
  }
}

///
.rightResponsiveLogout {
  width:725px
}

@media (max-width: 1300px) { 
  .rightResponsiveLogout {
      width:600PX
  }
}
@media (max-width: 1182px) { /* Adjust for 110% zoom on 1300px */
  .rightResponsiveLogout {
      width: 607px; /* Width for 110% zoom */
  }
  
}

@media (max-width: 1280px) { /* Adjust for 110% zoom on 1300px */
.rightResponsiveLogout {
    width: 530px; /* Width for 110% zoom */
}

}
@media (max-width: 1050px) { 
  .rightResponsiveLogout {
      width:559px
  }
}
@media (max-width: 1000px) { 
  .rightResponsiveLogout {
      width:445px;
      margin-left: 20px;
      
  }
}
@media (max-width: 1000px) { /* Adjust this value as needed */
  .rightResponsive {
    width: 400px;
  }
}


.hstack.large {
  width: 1320px;
}

.hstack.medium {
  width: 1165px;
}

.hstack.small {
  width: 1020px;
}

.notJobPage{
  width: 315px;
}

.logoutRightStack{
  width: 323px;
  padding-left: 50px;
}

@media (max-width: 1300px) { 
  .logoutRightStack {
      width: 555px;
  }
}
@media (max-width: 1182px) { /* Adjust for 110% zoom on 1300px */
  .logoutRightStack {
    width:270px
  }
}
@media (max-width: 1050px) { 
  .logoutRightStack {
    width:345px
  }
}
@media (max-width: 1000px) { /* Adjust this value as needed */
  .logoutRightStack {
    width:270px
  }
}