.siteFooterContainer{
    display: block;
    position: fixed;
    bottom: 0px;
    max-height: 200px;
    width: 100vw;
    z-index: 10;
}

.StaticPageFooter{
    margin-top: 60px;
    background: var(--chakra-colors-white);
    padding: 20px 0;
    border-top: 1px solid #E4E6EB;
}