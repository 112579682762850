.filterDropdownWrapper {
  position: relative;
  // height: 100%;
  // background-color: transparent;
  // border-radius: 12px 12px 12px 12px;
  // border: 1px solid transparent;
  // box-shadow: 0px 2px 6px 0px transparent;
}
.filterDropdownWrapper:hover {
  // background-color: #fff;
  // border-radius: 12px 12px 12px 12px;
  // border: 1px solid #DDDDDD;
  // box-shadow: 0px 2px 6px 0px #00000033;
}
.filterDropdown {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px 2px 6px 0px #00000033;
  border-radius: 0px 0px 12px 12px;
  padding: 10px 0px;
  width: 100%;
  margin-bottom: 40px;
}
.filterDropdown input{
  height: 30px;
  // border-left: 1px solid #5B5B5B;
  padding: 3px 25px;
  border-radius: 0;
}
.statusContainer {
    // background: var(--chakra-colors-gray-200);
    // box-shadow: 0px 2px 6px 0px #00000033!important;
    border-radius: 12px;
    display: flex;
    height: 44px;
    gap: 10px;
    align-items: center;
    color: var(--chakra-colors-brandPrimary-500);
    font-size: 15px;
    // border: 1px solid var(--chakra-colors-gray-300);
    cursor: pointer;
    position: relative;
  }
  .statusContainerActive {
    // background: var(--chakra-colors-gray-200);
    // box-shadow: 0px 2px 6px 0px #00000033!important;
    background-color: #fff;
    box-shadow: 0px 0px 6px 0px #00000033;
    border-radius:  12px 12px 0 0;
    display: flex;
    height: 44px;
    gap: 10px;
    align-items: center;
    color: var(--chakra-colors-brandPrimary-500);
    font-size: 15px;
    // border: 1px solid var(--chakra-colors-gray-300);
    cursor: pointer;
    position: relative;
    svg {
      transform: rotate(180deg);
    }
  }
  
  .statusContainerTest{
    // box-shadow: 0px 2px 6px 0px #00000033;
    border-radius: 12px;
    height: 44px;
    width: 210px;
    padding: 10px;
    display: flex;
    gap: 6px;
    align-items: center;
    color: var(--chakra-colors-brandPrimary-500);
    font-size: 15px;
    border-radius: 0px;
    border-bottom: 1px solid var(--chakra-colors-gray-300);
    cursor: pointer;
    div:last-child{
      display: none!important;
    }
    @media screen and (max-width:800px) {
      // padding: 10px 0;
    }
  }
  .statusIcon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    margin: 0px !important;
  }
  .statusDropdown {
    box-shadow: 0px 3px 6px 0px #00000033!important;
    margin-top: -8px;
    // margin-left: 5px;
    // box-shadow: none!important;
    outline: none;
    background-color: #fff !important;
    border-radius: 0px 0px 12px 12px !important;
    width: 100% !important;
    padding: 10px 0px;
  }
  .filterList label{
    padding: 0 20px;
    color: #5B5B5B;
  }
  .filterList label:hover {
    background-color: #E4E6EB;
  }
  .filterList label div {
    border: 0;
    background-color: transparent;
  }
  // [data-group="true"] {
  //   border-radius: 0;
  //   padding: 0 20px;
  //   border-radius: 0;
  //   input  {
  //     border-left: 1px solid #5B5B5B;
  //     border-radius: 0;
  //     padding: 0 10px;
  //     color: #5B5B5B;
  //   }
  // }
  .jobKeywordDropdown {
    border-radius: 12px 12px !important;
    box-shadow: 0px 3px 6px 0px #00000033!important;
    padding-bottom: 20px !important;
  }
  
.jobKeywordsForm {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px 2px 6px 0px #00000033;
  border-radius: 0px 0px 12px 12px;
  padding: 10px 0px;
  width: 100%;
  margin-bottom: 40px;
}